import config from '~/config';
// import { required, minLength, sameAs, email as emailv, maxLength } from 'vuelidate/lib/validators';
import { required, sameAs, email as emailv, numeric, maxValue, minValue } from 'vuelidate/lib/validators';

export const genericRequired = {
    value: {
        required,
    },
};

export const isCyrillic = {
    value: {
        isValid: (value) => /^[а-яА-ЯёЁ-]*$/.test(value),
    },
};

export const registrationFullName = {
    value: {
        required,
        isValid: (value) => /^[а-яА-ЯёЁ\s]*$/.test(value),
    },
};

export const requiredName = {
    value: {
        required,
        isValid: isCyrillic.value.isValid,
    },
};

export const singleName = {
    value: {
        isValid: isCyrillic.value.isValid,
    },
};

export const email = {
    value: {
        required,
        emailv,
    },
};

export const username = {
    value: {
        required,
        isValid: (value) => /^(8|7|\+7)?\d{10}$/.test(value.replace(/[^0-9+]/g, '')) || emailv(value),
    },
};

export const phone = {
    value: {
        required,
        isValid: (value) => /^(\+7)\d{10}$/.test((value || '').replace(/[^0-9+]/g, '')),
    },
};

export const digital = {
    value: {
        required,
        isValid: (value) => /[0-9+\- ()]/g.test(value),
    },
};

export const passportNumber = {
    value: {
        required,
        isLengthValid: value => !!value && (value + '').length === config.common.passportNumberLength,
    },
};

export const passportSeries = {
    value: {
        required,
        isLengthValid: value => !!value && (value + '').length === config.common.passportSeriesLength,
    },
};

export const snils = {
    value: {
        required,
        isValid: (valueRaw) => {
            let snils = valueRaw;
            if (typeof snils === 'number') {
                snils = snils.toString();
            } else if (typeof snils !== 'string') {
                snils = '';
            }
            if (!snils.length) {
                return false;
            }
            if (/[^0-9]/.test(snils)) {
                return false;
            }
            if (snils.length !== 11) {
                return false;
            }
            let sum = 0;
            for (let i = 0; i < 9; i++) {
                sum += parseInt(snils[i], 10) * (9 - i);
            }
            let checkDigit = 0;
            if (sum < 100) {
                checkDigit = sum;
            } else if (sum > 101) {
                checkDigit = parseInt(sum % 101);
                if (checkDigit === 100) {
                    checkDigit = 0;
                }
            }
            if (checkDigit === parseInt(snils.slice(-2))) {
                return true;
            }
            return false;
        },
    },
};

export const inn12 = {
    value: {
        required,
        isValid: (valueRaw) => {
            let value = valueRaw;
            if (!value) {
                return false;
            }
            if (typeof value === 'number') {
                value = value.toString();
            }
            if (typeof value !== 'string' ||
                value.length !== 12 ||
                value.split('').some((symbol) => isNaN(Number(symbol)))
            ) return false;

            let checkSumOne = (value.split('').slice(0, -2)
                .reduce(
                    (summ, symbol, index) =>
                        [7, 2, 4, 10, 3, 5, 9, 4, 6, 8][index] * Number(symbol) + summ,
                    0) %
                11) % 10;
            let checkSumTwo = (value.split('').slice(0, -1)
                .reduce(
                    (summ, symbol, index) =>
                        [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8][index] * Number(symbol) + summ,
                    0) %
                11) % 10;

            return (checkSumOne === Number(value[10]) && checkSumTwo === Number(value[11]));
        },
    },
};
export const inn10 = {
    value: {
        required,
        isValid: (valueRaw) => {
            let value = valueRaw;
            if (!value) {
                return false;
            }
            if (typeof value === 'number') {
                value = value.toString();
            }
            if (typeof value !== 'string' ||
                value.length !== 10 ||
                value.split('').some((symbol) => isNaN(Number(symbol)))
            ) return false;
            return Number(value[9]) === (value.split('').slice(0, -1)
                .reduce(
                    (summ, symbol, index) =>
                        [2, 4, 10, 3, 5, 9, 4, 6, 8][index] * Number(symbol) + summ,
                    0) %
                11) % 10;
        },
    },
};

export const ogrnip = {
    value: {
        required,
        isValid: (value) => /^\d+$/.test(value) && value.length === config.common.ogrnipLength,
    },
};

export const ogrn = {
    value: {
        required,
        isValid: (value) => /^\d+$/.test(value) && value.length === config.common.ogrnLength,
    },
};

export const ogrnEstate = {
    value: {
        required,
        isValid: (value) => /^\d+$/.test(value) && value.length,
    },
};

export const bik = {
    value: {
        required,
        isValid: (value) => /^\d+$/.test(value) && value.length === config.common.bikLength,
    },
};

export const kpp = {
    value: {
        required,
        isValid: (value) => /^\d+$/.test(value) && value.length === config.common.kppLength,
    },
};

export const kppEstate = {
    value: {
        required,
        isValid: (value) => /^\d+$/.test(value),
    },
};

export const amount = {
    value: {
        required,
        numeric,
    },
};

export const currentYearInvestments = {
    value: {
        required,
        numeric,
        minValue: minValue(0),
        maxValue: maxValue(config.common.annualInvestmentLimit),
    },
};

export const passwordNew = {
    value: {
        required,
        isValid: (value) => /^(?=.*[A-Za-z])(?=.*\d).+$/.test(value) && value.length >= config.common.minPasswordLength,
    },
};

export const passwordRepeat = {
    value: {
        required,
        sameAsPassword: sameAs(function() { return this.fields.password1.value; }),
    },
};

export const date = {
    value: {
        required,
        isValid: (value) => /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/.test(value) && value.length === 10,
    },
};

export const withdrawalAmount = (balance) => ({
    value: {
        required,
        numeric,
        minValue: minValue(1),
        maxValue: maxValue(balance),
    },
});
