import { mainClient } from '~/network';
import config from '~/config';

const requestDelay = 60000; // ms
const localStorageKey = config.localStorage.keyPrefix + config.localStorage.keyAffixies.sms.lastRequestDate;
const storedRequestCodeDate = window.localStorage.getItem(localStorageKey);
const storedRequestCodeDateParsed = storedRequestCodeDate ? parseInt(storedRequestCodeDate, 10) : null;
const requestCodeIsAvailableInitial = storedRequestCodeDateParsed ? Date.now() - storedRequestCodeDateParsed >= requestDelay : true;
let timer = null;

const checkTime = (state) => {
    let newTimerValue = state.codeRequestDate + state.codeRequestDelay - Date.now();
    if (newTimerValue <= 0) {
        newTimerValue = 0;
        state.codeRequestIsAvailable = true;
        clearInterval(timer);
        timer = null;
    }
    state.codeRequestTimer = newTimerValue;
};

const state = {
    getCodeIsLoading: false,
    getCodeError: null,
    getCodeResult: null,

    codeRequestIsAvailable: requestCodeIsAvailableInitial,
    codeRequestDate: storedRequestCodeDateParsed,
    codeRequestTimer: 0, // ms
    codeRequestDelay: requestDelay, // ms

    checkCodeIsLoading: false,
    checkCodeError: null,
    checkCodeResult: null,

    getAuthCodeIsLoading: false,
    getAuthCodeError: null,
    getAuthCodeResult: null,

    getPasswordResetCodeResult: null,
    getPasswordResetCodeIsLoading: false,
    getPasswordResetCodeError: null,

    getPasswordResetCodeRequestIsAvailable: true,
    getPasswordResetCodeRequestDate: null,
    getPasswordResetCodeRequestDelay: 60000, // ms
    getPasswordResetCodeRequestTimer: 0, // ms
};

if (!requestCodeIsAvailableInitial) {
    checkTime(state);
    timer = setInterval(() => {
        checkTime(state);
    }, 1000);
}

const getters = {
    sms(state) {
        return state;
    },
};

const actions = {
    async checkCode({ state }, payload) {
        if (state.checkCodeIsLoading) {
            return;
        }
        state.checkCodeIsLoading = true;
        state.checkCodeError = null;
        const { data, error, status } = await mainClient.sms.requests.checkCode({
            code: payload.code,
            value: payload.phone,
        });
        if (error) {
            console.error(error, status);
            console.error('Error above relates to component page-cabinet-verification-confirmation checkCode method');
            state.checkCodeError = 'Неверный код';
            state.checkCodeResult = null;
        } else {
            if (data.result) {
                state.checkCodeResult = data.result;
            } else {
                state.checkCodeError = 'Неверный код';
                state.checkCodeResult = null;
            }
        }
        state.checkCodeIsLoading = false;
    },
    resetCheckCode({ state }) {
        state.checkCodeIsLoading = false;
        state.checkCodeError = null;
        state.checkCodeResult = null;
    },
    async getCode({ state }, payload) {
        if (state.getCodeIsLoading) {
            return;
        }
        if (!state.codeRequestIsAvailable) {
            state.getCodeError = 'Минута с прошлого запроса еще не прошла, попробуйте позже';
            state.getCodeResult = null;
            return;
        }
        state.getCodeError = null;
        state.getCodeIsLoading = true;

        const { data, error, status } = await mainClient.sms.requests.getCode({ value: payload.phone });
        if (error) {
            console.error(error, status);
            state.getCodeError = 'Ошибка запроса кода';
            state.getCodeResult = null;
        } else {
            state.getCodeError = null;
            state.getCodeResult = data;
            state.codeRequestIsAvailable = false;
            state.codeRequestDate = Date.now();
            window.localStorage.setItem(localStorageKey, Date.now());

            checkTime(state);
            if (!timer) {
                timer = setInterval(() => {
                    checkTime(state);
                }, 1000);
            }
        }

        state.getCodeIsLoading = false;
    },
    resetGetCode({ state }) {
        state.getCodeIsLoading = false;
        state.getCodeError = null;
        state.getCodeResult = null;
    },
    /**
     * Получает авторизационный код для пользователя.
     *
     * @async
     * @function getAuthCode
     * @param {Object} payload - Объект с данными для запроса авторизационного кода.
     * @param {string} payload.recaptcha - Результат проверки reCAPTCHA.
     * @param {string} payload.value - Номер телефона.
     * @returns {Promise<void>}
     */
    async getAuthCode({ state }, payload) {
        if (state.getAuthCodeIsLoading) {
            return;
        }
        if (!state.codeRequestIsAvailable) {
            state.getAuthCodeError = 'Минута с прошлого запроса еще не прошла, попробуйте позже';
            state.getAuthCodeResult = null;
            return;
        }
        state.getAuthCodeError = null;
        state.getAuthCodeIsLoading = true;

        let mobile = null;
        if (payload.value) {
            mobile = /^(8|7|\+7)?\d{10}$/.exec(payload.value?.replace(/[^0-9+]/g, ''))?.[0];
        }
        const { data, error, status } = await mainClient.sms.requests.getAuthCode({
            ...payload,
            value: mobile ? `+7${mobile.slice(-10)}` : payload.value,
        });
        if (error) {
            console.error(error, status);
            state.getAuthCodeError = 'Ошибка запроса кода';
            state.getAuthCodeResult = null;
        } else {
            state.getAuthCodeError = null;
            state.getAuthCodeResult = data;
            state.codeRequestIsAvailable = false;
            state.codeRequestDate = Date.now();
            window.localStorage.setItem(localStorageKey, Date.now());

            checkTime(state);
            if (!timer) {
                timer = setInterval(() => {
                    checkTime(state);
                }, 1000);
            }
        }

        state.getAuthCodeIsLoading = false;
    },
    resetGetAuthCode({ state }) {
        state.getAuthCodeIsLoading = false;
        state.getAuthCodeError = null;
        state.getAuthCodeResult = null;
    },
    async getPasswordResetCode({ state }, payload) {
        if (state.getPasswordResetCodeIsLoading) {
            return;
        }
        if (!state.codeRequestIsAvailable) {
            state.getPasswordResetCodeError = 'Минута с прошлого запроса еще не прошла, попробуйте позже';
            state.getPasswordResetCodeResult = null;
            return;
        }
        state.getPasswordResetCodeError = null;
        state.getPasswordResetCodeIsLoading = true;

        const { error, status } = await mainClient.sms.requests.getPasswordResetCode(payload);
        if (error) {
            console.error(error, status);
            state.getPasswordResetCodeError = 'Ошибка запроса кода';
            state.getPasswordResetCodeResult = null;
        } else {
            // server send no data for this request
            state.getPasswordResetCodeResult = 'success';
            state.getPasswordResetCodeError = null;
            state.codeRequestIsAvailable = false;
            state.codeRequestDate = Date.now();
            window.localStorage.setItem(localStorageKey, Date.now());

            checkTime(state);
            if (!timer) {
                timer = setInterval(() => {
                    checkTime(state);
                }, 1000);
            }
        }

        state.getPasswordResetCodeIsLoading = false;
    },
    resetGetAuthCodeResetCode({ state }) {
        state.getPasswordResetCodeIsLoading = false;
        state.getPasswordResetCodeError = null;
        state.getPasswordResetCodeResult = null;
    },
};

const mutations = {
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
