<template>
    <section class="plugin-estate-charts-item"
        v-if="graphData"
        v-bind:class="{ _empty: isEmpty }"
        >
        <div class="plugin-estate-charts-item__loader"
            v-if="isLoadig"
            >
            <div class="plugin-estate-charts-item__loader-container">
                <ui-loader />
            </div>
        </div>
        <h3 class="plugin-estate-charts-item__title"
            v-text="title"
        />
        <div class="plugin-estate-charts-item__graph">
            <ui-graph
                v-if="graphData"
                v-bind:type="graphType"
                v-bind:data="values"
                v-bind:categories="categories"
            />
        </div>
    </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'plugin-estate-charts-item',
    inject: [ 'estateId' ],
    props: {
        type: {
            type: String,
            default: 'shares',
        },
        providedData: {
            type: Object,
            default: null,
        },
    },
    computed: {
        ...mapState('estates', [
            'getChartSharesDataResult',
            'getChartSharesDataIsLoading',
            'getChartSharesDataError',

            'getChartDividendsDataResult',
            'getChartDividendsDataIsLoading',
            'getChartDividendsDataError',
        ]),
        title() {
            return this.type === 'shares' ? 'Стоимость акции, руб.' : 'Выплаченные дивиденды на 1 акцию, руб.';
        },
        graphData() {
            if (this.providedData) {
                return this.providedData.items;
            }
            if (this.type === 'shares') {
                return this.getChartSharesDataResult || null;
            } else if (this.type === 'dividends') {
                return this.getChartDividendsDataResult || null;
            }
            return null;
        },
        values() {
            if (this.providedData) {
                return this.providedData.items.map(x => x.value);
            }
            if (!this.graphData) return null;
            let result = this.graphData.map(x => x.value);
            if (this.type === 'shares' && result.length > 0) {
                let nonZeroValue, firstNonZeroIndex;
                for (let i = 0; i < result.length; i++) {
                    if (result[i] !== 0) {
                        if (firstNonZeroIndex === undefined) {
                            firstNonZeroIndex = i;
                        }
                        nonZeroValue = result[i];
                    } else if (nonZeroValue !== undefined) {
                        result[i] = nonZeroValue;
                    }
                }
                for (let i = firstNonZeroIndex - 1; i >= 0; i--) {
                    result[i] = result[firstNonZeroIndex];
                }
            } else if (this.type === 'dividends') {
                result = result.filter(x => x !== 0);
            }
            return result;
        },
        categories() {
            if (this.providedData) {
                return this.providedData.items.map(x => x.title);
            }
            if (!this.graphData) return null;
            let result = [];
            if (this.type === 'shares') {
                result = this.graphData.map(x => x.category);
                if (this.type === 'shares' && result.length > 0) {
                    result[0] = 'Покупка объекта';
                }
            } else if (this.type === 'dividends') {
                result = this.graphData.map(x => x.value !== 0 ? x.category : null).filter(x => x !== null);
            }
            return result;
        },
        graphType() {
            return this.type === 'shares' ? 'line' : 'bar';
        },
        isEmpty() {
            return this.values?.length === 0;
        },
        isLoadig() {
            return !this.graphData;
        },
    },
    methods: {
        getData() {
            if (this.type === 'shares' && !this.getChartSharesDataResult) {
                this.$store.dispatch('estates/getChartSharesData', { id: this.estateId });
            } else if (this.type === 'dividends' && !this.getChartDividendsDataResult) {
                this.$store.dispatch('estates/getChartDividendsData', { id: this.estateId });
            }
        },
    },
    mounted() {
        if (this.providedData) {

        } else {
            this.getData();
        }
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-estate-charts-item {
    position: relative;
    &._empty {
        display: none;
    }
    &__loader {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;

        display: flex;
        justify-content: center;
        align-items: center;

        background-color: @color-gray-lightest;
        &-container {
            width: 50px;
        }
    }
    &__title {
        .typography-large();

        margin: 0 0 32px;
    }
    &__graph {
        width: auto;
        height: 225px;
        margin: 0 0 0 -16px;
    }
    @media @media-md-down {
        &__title {
            .typography-medium();
        }
    }
}
</style>
